import * as axios from "axios";
import { AxiosResponse } from "axios";
import {
  approveApplicationDocumentsType,
  approveApplicationType,
  declineApplicationType,
  getDocumentType,
  getPhotoType,
  partnerLinkResponseType,
  underwriterBasisType,
  updateApplicationType,
  uploadApplicationDocumentType,
} from "./types";
import { applicationType } from "../../types/Applications/types";
import { mainAxios } from "../main";
import {
  confirmPhotosType,
  getDocumentPreviewById,
} from "../main/documents/types";
import { getScheduleType } from "../main/services/types";
import { dataToQueryString } from "../methods/dataToQueryString";
import { getApplicationsQueryType } from "../main/application/types";

export const underwriterAxios = axios.default.create({
  baseURL: process.env.REACT_APP_UNDERWRITER_URL,
  timeout: 30000,
});

class UnderwriterApiClass {
  constructor() {}

  //Получить информацию обо всех заявках андеррайтера
  getApplications = (
    query?: getApplicationsQueryType
  ): Promise<AxiosResponse<any>> =>
    underwriterAxios.get(`/application${dataToQueryString(query)}`);

  //Получить информацию о заявке
  getApplication = ({
    applicationId,
    abortSignal,
  }: underwriterBasisType): Promise<AxiosResponse<any>> => {
    return underwriterAxios.get(`/application/${applicationId}`, {
      signal: abortSignal,
    });
  };

  getElptsExtract = ({
    applicationId,
  }: underwriterBasisType): Promise<AxiosResponse<any>> =>
    underwriterAxios.get(`application/${applicationId}/document/elpts_extract`);

  getPartnerLink = ({
    applicationId,
  }: underwriterBasisType): Promise<
    AxiosResponse<{ data: partnerLinkResponseType }>
  > => underwriterAxios.get(`application/${applicationId}/partner-link`);

  //Обновить информацию о заявке
  updateApplication = ({
    applicationId,
    ...applicationBody
  }: underwriterBasisType) =>
    underwriterAxios.put(`/application/${applicationId}`, applicationBody);

  //Обновление заявки patch
  updateApplicationPatch = ({
    applicationId,
    ...applicationBody
  }: // вкорячил эни потому что поля в патче поменялись, надо переписывать тип
  // TODO: выпилить эни
  updateApplicationType & any) =>
    underwriterAxios.patch<any>(
      `/application/${applicationId}`,
      applicationBody
    );

  // Перевести заявку в видео-звонок
  putApplicationToVideoCallStatus = ({
    applicationId,
  }: {
    applicationId: applicationType["id"];
  }) => underwriterAxios.put(`/application/${applicationId}/video-call`);

  //Добавить офферы
  sendOffers = ({ applicationId, ...body }: approveApplicationType) =>
    underwriterAxios.put(`/application/${applicationId}/offer`, body);

  //Принять решение об одобрении заявки андером (вкладка 7)
  approveApplication = ({ applicationId }: approveApplicationDocumentsType) =>
    underwriterAxios.put(`/application/${applicationId}/approve:documents`);

  //Принять решение об одобрении заявки
  approveApplicationSuperunderwriter = ({
    applicationId,
    ...body
  }: {
    applicationId: number;
    sms_code: string | null | undefined;
  }) => underwriterAxios.put(`/application/${applicationId}/approve`, body);

  //Принять решение об отказе по заявке
  declineApplication = ({ applicationId, ...body }: declineApplicationType) =>
    underwriterAxios.delete(`/application/${applicationId}`, { data: body });

  //Скачать документ
  downloadFile = ({ applicationId, document_type }: getDocumentType) =>
    underwriterAxios.get(
      `/application/${applicationId}/document/${document_type}`
    );

  getPhotoPreview = ({ applicationId, photo_type }: getPhotoType) =>
    underwriterAxios.get(
      `/application/${applicationId}/photo/${photo_type}/preview`
    );

  getPhotoPreviewById = ({
    applicationId,
    photo_id,
    abortSignal,
  }: getDocumentPreviewById) =>
    mainAxios.get<{ data: { content: string; file_name: string } }>(
      `/application/${applicationId}/photo/${photo_id}/download:preview`,
      {
        signal: abortSignal,
      }
    );

  getPhotoFull = ({ applicationId, photo_type }: getPhotoType) =>
    underwriterAxios.get<{ data: { content: string; file_name: string } }>(
      `/application/${applicationId}/photo/${photo_type}`
    );

  getPhotoFullById = ({
    applicationId,
    photo_id,
    abortSignal,
  }: getDocumentPreviewById) =>
    mainAxios.get<{ data: { content: string; file_name: string } }>(
      `/application/${applicationId}/photo/${photo_id}/download`,
      {
        signal: abortSignal,
      }
    );

  confirmPhotos = ({ application_id, ...body }: confirmPhotosType) =>
    mainAxios.post<{ data: applicationType }>(
      `/application/${application_id}/photo-decision`,
      body
    );

  uploadApplicationDocument = ({
    applicationId,
    ...data
  }: uploadApplicationDocumentType) =>
    underwriterAxios.post(`/application/${applicationId}/document`, data);

  // Вылогинить андеррайтера
  logout = () => underwriterAxios.put("/logout");
}

export const underwriterApi = new UnderwriterApiClass();
