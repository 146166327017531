import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  applicationListType,
  applicationType,
  userInfoType,
} from "../../../types/Applications/types";
import { StateType } from "../../index";

type initialStateType = {
  // currentApplication: applicationType | null;
  applications: applicationListType[] | null;
  // applications: {
  //   id: applicationType["id"];
  //   application_type: applicationType["application_type"];
  //   date_of_application: applicationType["date_of_application"];
  //   user_info: Partial<applicationType["user_info"]>;
  // }[] | null;
  currentApplication: applicationType | null;
  saveCurrentApplication: boolean;
  clickedApplicationId: number | undefined;
  loadingApprove: boolean;
  loadingDecline: boolean;
  onlyCurrentUnderwriter: string;
};

const initialState: initialStateType = {
  // currentApplication: null,
  applications: null,
  currentApplication: null,
  saveCurrentApplication: false,
  clickedApplicationId: undefined,
  loadingApprove: false,
  loadingDecline: false,
  onlyCurrentUnderwriter: "",
};

export const applicationsReducer = createSlice({
  name: "@applicationReducer",
  initialState,
  reducers: {
    /*
      sortApplications вынес отсюда, чтобы класть сюда сразу отсортированные в аргументе

     */
    setApplications: (
      state,
      { payload }: PayloadAction<{ applications: applicationType[] }>
    ) => {
      // const sortApplications = payload.applications.sort(
      //   (a: any, b: any) =>
      //     // @ts-ignore
      //     moment(a.created_at) - moment(b.created_at)
      // );
      // console.log("PAYLOAD", payload);

      // state.applications = payload.applications;

      state.applications = payload.applications.map((item) => {
        return {
          id: item.id,
          application_type: item.application_type,
          created_at: item.created_at,
          date_of_application: item.date_of_application,
          user_info: {
            first_name: item.user_info.first_name,
            last_name: item.user_info.last_name,
            middle_name: item.user_info.middle_name,
          },
        };
      });
      /*
        Тут нам нужна самая свежая заявка в статусе new.
        currentApplication теперь это самая свежая заявка, а не та, с которой мы сейчас работаем
        (добавился видеозвонок, в котором порядок не важен)
        А для заявок со статусом new андер может работать только с последней.
       */
      // state.currentApplication = payload.applications.find((item: any) => item.status ===
      // "underwriter_scoring_process");
    },
    /*
      Заявка, с которой сейчас работает андер (может быть и video_call и new)
    */
    setCurrentApplication: (state, { payload }: PayloadAction<any>) => {
      state.currentApplication = payload;
    },
    setSaveCurrentApplication: (state, { payload }: PayloadAction<boolean>) => {
      state.saveCurrentApplication = payload;
    },
    setOnlyCurrentUnderwriterApplication: (
      state,
      { payload }: PayloadAction<string>
    ) => {
      state.onlyCurrentUnderwriter = payload;
    },

    setClickedApplicationId: (state, { payload }: PayloadAction<number>) => {
      state.clickedApplicationId = payload;
    },
    setLoadingApprove: (state, { payload }: PayloadAction<any>) => {
      state.loadingApprove = payload;
    },
    setLoadingDecline: (state, { payload }: PayloadAction<any>) => {
      state.loadingDecline = payload;
    },
    clear: () => initialState,
  },
});

export const applicationsReducerSelectors = {
  getApplications: (state: StateType) => state.applicationsReducer.applications,
  // getCurrentApplication: (state: StateType) =>
  //   state.applicationsReducer.currentApplication,
  getCurrentApplication: (state: StateType) =>
    state.applicationsReducer.currentApplication,
  getSaveCurrentApplication: (state: StateType) =>
    state.applicationsReducer.saveCurrentApplication,
  setLoadingApprove: (state: StateType) =>
    state.applicationsReducer.loadingApprove,
  setLoadingDecline: (state: StateType) =>
    state.applicationsReducer.loadingDecline,
  getOnlyCurrentUnderwriterApplication: (state: StateType) =>
    state.applicationsReducer.onlyCurrentUnderwriter,
};
